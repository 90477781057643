// Home.js
import React from "react";
import Page from "../../components/Page";
import ParentSection from "../../components/ParentSection";
import BannerComponent from "../../components/BannerComponent";

import imageSindromePanico from '../../assets/sindrome-panico-image.jpg'
import imageAnsiedade from '../../assets/ansiedade-image.jpg'
import imageDepressao from '../../assets/depressao-image.jpg'
import imageLuto from '../../assets/luto-image.jpg'
import fotoRosangelo from '../../assets/rosangelo-foto-image.png'
import bannerImage from './../../assets/banner-image.jpg'

function Home() {
  const sectionsData = [
    {
      title: "Síndrome do Pânico",
      content: "Atendimento psicoterapêutico para pessoas que sofrem com a Síndrome do Pânico, proporcionando o desenvolvimento de habilidades de enfrentamento e o tratamento adequado para essa condição",
      image: imageSindromePanico,
    },
    {
      title: "Ansiedade",
      content: "Através da psicoterapia, é possível auxiliar no manejo e tratamento da ansiedade, proporcionando ao paciente uma melhor qualidade de vida e bem-estar emocional.",
      image: imageAnsiedade,
    },
    {
      title: "Depressão",
      content: "Atendimento psicoterapêutico para pessoas que sofrem com a depressão, visando o alívio dos sintomas, o fortalecimento emocional e a busca por uma vida mais equilibrada e satisfatória.",
      image: imageDepressao,
    },
    {
      title: "Luto",
      content: "Acompanhamento psicoterapêutico para pessoas que estão passando pelo processo de luto, proporcionando suporte emocional e auxiliando na elaboração e aceitação da perda.",
      image: imageLuto,
    },
  ];

  const bannerText = [
    "Atendimento psicoterapêutico na abordagem gestáltica para adolescentes e adultos.",
    "Desenvolvimento de habilidades de enfrentamento, tratamento da ansiedade e fortalecimento de potencialidades.",
  ];

  const welcomeSectionData = [
    {
      title: "Seja bem-vindo",
      content: "Olá, sou Rosangêlo Henrique, psicólogo com formação em Gestalt-terapia e voltado para o acolhimento de pessoas adolescentes, adultos e idosos, além de casais e grupos terapêuticos. A Gestalt-terapia é uma abordagem que visa promover o auto-crescimento e desenvolvimento de suas potencialidades individuais. Será um grande prazer poder compartilhar e participar desse processo",
      image: fotoRosangelo,
      moreLink:"/",
    },   
  ];

  return (
    <Page>
      <BannerComponent
        imageUrl={bannerImage}
        title=""
        text={bannerText}
        buttonText="Entre em Contato"
      />
      <ParentSection sectionTitle="" sectionData={welcomeSectionData}>
      </ParentSection>
      <ParentSection sectionTitle="Especialidades Atendidas" sectionData={sectionsData}
        backgroundColor="#E2FFF0"
      />
    </Page>
  );
}

export default Home;
