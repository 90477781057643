// App.js
import React from "react";
import Home from "./pages/Home";

import "./App.css"; // Correção: remova a palavra-chave "from" e adicione ponto e vírgula

function App() {
  return (
    <div className="appContent">
      <Home />
    </div>
  );
}
export default App;
