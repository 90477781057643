import React from "react";
import SectionElement from "./../../components/SectionElement";
import style from './ParentSection.module.css'

function ParentSection({ sectionTitle, sectionData, backgroundColor }) {
  const contentStyle = {
    backgroundColor: backgroundColor || 'transparent', // Use a cor fornecida ou 'transparent' como padrão
  };

  return (
    <div className={style.parentSectionContainer}>
      <div className={style.parentSectionContent} style={contentStyle}>
        <h2 className={style.parentSectionContentTitle}>{sectionTitle}</h2>
        {sectionData.map((data, index) => (
          <SectionElement key={index} {...data} />
        ))}
      </div>
    </div>
  );
}

export default ParentSection;
