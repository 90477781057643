// BannerComponent.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./BannerComponent.module.css";

const BannerComponent = ({ imageUrl, title, text, buttonText, buttonColor }) => {
  const handleButtonClick = () => {
    // Adicione aqui a lógica para abrir o link do WhatsApp
    window.open("https://api.whatsapp.com/send?phone=5581996094756", "_blank");
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerContent}>
        <img src={imageUrl} alt="Banner" className={styles.bannerImage} />
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <h2>{title}</h2>
            {Array.isArray(text) ? (
              text.map((paragraph, index) => <p key={index}>{paragraph}</p>)
            ) : (
              <p>{text}</p>
            )}
            {buttonText && (
              <button style={{ backgroundColor: buttonColor }} onClick={handleButtonClick}>
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BannerComponent.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
};

export default BannerComponent;
