// Footer.js
import React from "react";
import style from "./Footer.module.css";
import whatsappIcon from "../../assets/footer-whatsapp-icon.png";
import instagramIcon from "../../assets/footer-instagram-icon.png";
import emailIcon from "../../assets/footer-envelope-icon.png";

const socialIcons = [
  { image: whatsappIcon, alt: "WhatsApp", url: "https://api.whatsapp.com" },
  { image: instagramIcon, alt: "Instagram", url: "https://www.instagram.com/rosangelohenrique/" },
  { image: emailIcon, alt: "Email", url: "mailto:rosangelo@rhspsi.com" },
];

function Footer() {
  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <footer className={style.footerContainer}>
      <div className={style.footerContent}>
        <div className={style.footerTitle}>
          <p>Rosangelo Henrique Psicólogo</p>
        </div>
        <nav className={style.footerSocialIconsContainer}>
          <ul className={style.footerSocialIcons}>
            {socialIcons.map((icon, index) => (
              <li key={index} onClick={() => handleClick(icon.url)}>
                <img src={icon.image} alt={icon.alt} />
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;