// SectionElement.js
import React from "react";
import style from './SectionElement.module.css'

function SectionElement({ title, content, image, moreLink }) {
  return (
    <div className={style.sectionElementContainer}>
      <div className={style.sectionElementContent}>
        {image && <img className={style.sectionImage} src={image} alt="Imagem da Seção" />}
        <div className={style.sectionElementText}>
          <h3>{title}</h3>
          <p>{content}</p>
          {moreLink && <a href={moreLink} className={style.moreLink}>Mais</a>}
        </div>
      </div>
    </div>
  );
}

export default SectionElement;
