// Page.js
import React from "react";
import Footer from "../Footer";
import Header from "../Header";

function Page({ children }) {
  return (

    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default Page;