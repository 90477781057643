// Header.js
import React, { useState, useEffect } from "react";
import instagramIcon from "./../../assets/instagram-icon.png";
import whatsappIcon from "./../../assets/whatsapp-icon.png";
import emailIcon from "./../../assets/envelope-icon.png";
import style from './Header.module.css';
import menuIcon from "./../../assets/menu-icon.png"

function Header() {
    const whatsappNumber = "8196094756";
    const instagramUrl = "https://www.instagram.com/rosangelohenrique/";
    const emailAddress = "rosangelo@rhspsi.com";

    const openWhatsApp = () => {
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, "_blank");
    };

    const openInstagram = () => {
        window.open(instagramUrl, "_blank");
    };

    const openEmail = () => {
        window.location.href = `mailto:${emailAddress}`;
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <header className={style.headerContainer}>
            {isMobile && (
                <div className={`${style.menuIcon}`} onClick={toggleMobileMenu}>
                    <img src={menuIcon} alt="Menu" />
                </div>
            )}
            <div className={style.headerContent}>
                <h1>Rosangelo Henrique Psicólogo</h1>
                <nav className={isMobile ? `${style.headerMenu} ${showMobileMenu ? style.showMobileMenu : ''}` : style.headerMenu}>
                    <ul className={style.headerMenu}>
                        <li className={style.menuItem} onClick={() => setShowMobileMenu(false)}>Início</li>
                        <li className={style.menuItem} onClick={() => setShowMobileMenu(false)}>Sobre</li>
                    </ul>
                    <ul className={style.headerMenu}>
                        <li className={`${style.menuItem} ${style.socialIcon} ${style.footerSocialIcon}`} onClick={openWhatsApp}>
                            <img src={whatsappIcon} alt="WhatsApp" style={{ cursor: "pointer" }} />
                        </li>
                        <li className={`${style.menuItem} ${style.socialIcon} ${style.footerSocialIcon}`} onClick={openInstagram}>
                            <img src={instagramIcon} alt="Instagram" style={{ cursor: "pointer" }} />
                        </li>
                        <li className={`${style.menuItem} ${style.socialIcon} ${style.footerSocialIcon}`} onClick={openEmail}>
                            <img src={emailIcon} alt="Email" style={{ cursor: "pointer" }} />
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
